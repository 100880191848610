import { useContext } from "react";
import { inputUpdated, updateProperty } from ".";
import TabWrapper, { ITabComponent } from "../../../components/TabWrapper";
import { ConnectionType, UserRole } from "../../../models";
import { AdvancedSettings } from "./AdvancedSettings";
import { BasicSettings } from "./BasicSettings";
import CustomReportListCard from "./CustomReportListCard";
import PermissionsCard, { IFullPermission } from "./PermissionsCard";
import { ConnectionContext } from "./ConnectionContext";
import { Col } from "reactstrap";
import { ExploreDataButton } from "../../../components/buttons/ExploreDataButton";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { compareStrings } from "../../../utility/CompareStrings";
import { isEmpty } from "lodash";
import { IDriverExtended } from "../../../bffmodels/IDriverExtended";
import { getSelectedNetSuiteSchema } from "./utils/netsuite/getSelectedNetSuiteSchema";
import { useIsConnectForSpreadsheets } from "../../../hooks/useIsConnectForSpreadsheets";
import { DocumentationPane } from "./DocumentationPane";

interface IConnectionTabs {
  displayedBasicDriverProperties: JSX.Element[];
}

export const ConnectionTabs = ({
  displayedBasicDriverProperties,
}: IConnectionTabs) => {
  const context = useContext(ConnectionContext);
  const {
    connectionId,
    connectionInfo,
    driverInfo,
    driverType,
    isCacheConnection,
    isInitialSetupPage,
    isOEMConnection,
    fromSpreadsheetsClient,
    oauthProps,
    setUnsavedChanges,
  } = context;

  const userInfo = useUserInfo();
  const isAdmin = userInfo.IsInRole(UserRole.Admin);
  const isSpreadsheetUser = useIsConnectForSpreadsheets();

  const onCredentialTypeChange = (newCredentialType: string) => {
    const selectedConnectionType = compareStrings(newCredentialType, "shared")
      ? ConnectionType.Shared
      : ConnectionType.UserDefined;

    if (connectionId) {
      driverInfo.userCredentialPropertyNames.forEach((property: string) => {
        updateProperty(context, property, "");
      });
    }

    context.setConnectionInfo!({
      ...connectionInfo!,
      selectedConnectionType: selectedConnectionType,
      // If the user edits an existing connection and changes the credentials type,
      // we clear out the OAuth token. They need to click Sign In again unless they have already clicked
      // sign-in on this page and we're storing their OAuth token on the client.
      isOauthTokenPresent: !isEmpty(oauthProps),
    });
    const newDriverInfo = { ...driverInfo } as IDriverExtended;
    newDriverInfo.selectedConnectionType =
      selectedConnectionType as any as number;
    context.setDriverInfo(newDriverInfo);
    setUnsavedChanges(true);
  };

  function getIsCustomReportsVisible(): boolean {
    if (isCacheConnection) return false;

    if (driverInfo.reportTypes == null || driverInfo.reportTypes.length === 0)
      return false;

    if (isAdmin || isOEMConnection) return true;

    return false;
  }

  const isSuiteSQLForNetSuite = compareStrings(
    getSelectedNetSuiteSchema(driverInfo),
    "SuiteQL",
  );

  const tabs: ITabComponent[] = [
    {
      tabName: "Basic Settings",
      tabEnum: "1",
      tabComponent: (
        <BasicSettings
          displayedBasicDriverProperties={displayedBasicDriverProperties}
          onCredentialTypeChange={onCredentialTypeChange}
          inputUpdated={inputUpdated}
        />
      ),
      isVisible: true,
    },
    {
      tabName: "Advanced Settings",
      tabEnum: "2",
      tabComponent: (
        <AdvancedSettings
          location="EditConnection"
          displayedBasicDriverProperties={displayedBasicDriverProperties}
        />
      ),
      isVisible: true,
    },
    {
      tabName: "Permissions",
      tabEnum: "3",
      tabComponent: (
        <PermissionsCard
          permissions={context.permissions as IFullPermission[]}
          updatePermissions={(newPermissions: IFullPermission[]) => {
            context.setPermissions(newPermissions);
          }}
          rowColumnName={"Users"}
          setUnsavedChanges={() => setUnsavedChanges(true)}
        />
      ),
      isVisible:
        !isInitialSetupPage &&
        isAdmin &&
        !isCacheConnection &&
        !isOEMConnection &&
        !isSpreadsheetUser,
    },
    {
      tabName: "Custom Reports",
      tabEnum: "4",
      tabComponent: (
        <CustomReportListCard
          id={connectionId}
          driver={driverType}
          reportTypes={driverInfo.reportTypes ?? []}
        />
      ),
      isVisible: getIsCustomReportsVisible(),
      isDisabled: !connectionInfo.isTested || isSuiteSQLForNetSuite,
      disabledTooltip: isSuiteSQLForNetSuite
        ? "Custom reports are not available when using SuiteQL as your Schema setting."
        : "Custom reports are available for this data source after you create & test your connection.",
    },
  ];

  const exploreDataButton =
    isInitialSetupPage ||
    isOEMConnection ||
    fromSpreadsheetsClient ||
    isCacheConnection ? undefined : (
      <Col className="float-end-explore-data-btn mb-2 text-nowrap">
        <ExploreDataButton isDisabled={!connectionInfo.isTested} />
      </Col>
    );

  const hideDocumentationPane =
    isInitialSetupPage ||
    isOEMConnection ||
    fromSpreadsheetsClient ||
    context.currentTab === "3" || // Permissions tab
    context.currentTab === "4"; // Custom Reports tab

  const persistentDocumentationPane = (
    <Col
      hidden={hideDocumentationPane}
      className="connection-documentation-column"
    >
      <DocumentationPane driverType={driverType} />
    </Col>
  );

  return (
    <span className="connection-tabs-container">
      <TabWrapper
        tabs={tabs}
        currentTab={context.currentTab}
        setCurrentTab={context.setCurrentTab}
        extraButton={exploreDataButton}
        persistentElement={persistentDocumentationPane}
      />
    </span>
  );
};

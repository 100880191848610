import { useContext, useEffect, useState } from "react";
import { ConnectionType, UserRole } from "../../../models";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Form } from "react-bootstrap";
import CredentialCard from "../components/CredentialCard";
import {
  getBetaIcon,
  getDriverIcon,
} from "../../../components/drivers/DriverIconFactory";
import { ConnectionContext, IConnectionContext } from "./ConnectionContext";
import SpecialConnectionElements from "./specialElements/SpecialConnectionElements";
import { ToastrError } from "../../../services/toastrService";
import { compareStrings } from "../../../utility/CompareStrings";
import { isOAuthISUSelected } from "./oauth/isOAuthISUSelected";
import PrerequisiteWarningCard from "./PrerequisiteWarningCard";
import { formatUTCDateTime } from "../../../utility/FormatUTCDateTime";
import { getSelectedAuthSchemeValue } from "./oauth/getSelectedAuthScheme";
import { ConnectionStatusBadge } from "./ConnectionStatusBadge";
import { SSHConnectionTypeSelector } from "./SSHConnectionTypeSelector";
import { hasSSH } from "./ConnectionFunctions";
import { useAppSelector } from "../../../redux/hooks";
import { CDataTypography } from "../../../components/text/CDataTypography";

interface IBasicSettingsProps {
  displayedBasicDriverProperties: React.JSX.Element[];
  onCredentialTypeChange: (newCredentialType: string) => void;
  inputUpdated?: (
    event: React.MouseEvent | React.KeyboardEvent,
    connectionContext: IConnectionContext,
  ) => void;
}

export function BasicSettings(props: IBasicSettingsProps) {
  const connectionContext = useContext(ConnectionContext);
  const currentUser = useAppSelector((state) => state.user);
  const [connectionNameInvalid, setConnectionNameInvalid] = useState(false);
  const [connectionNameInvalidMessage, setConnectionNameInvalidMessage] =
    useState("");
  const [prerequisiteChecked, setPrerequisiteChecked] = useState(
    connectionContext.connectionId != null,
  );
  const [isPrerequisiteCheckInvalid, setPrerequisiteCheckInvalid] =
    useState(false);

  const showOAuthClientPrerequisite =
    getSelectedAuthSchemeValue(connectionContext.driverInfo) === "oauthclient";

  const validateConnectionName = (event: any) => {
    const connectionName = event.target.value;
    let invalid, invalidMessage;
    if (connectionName.length < 1 || connectionName.length > 100) {
      invalid = true;
      invalidMessage = "Connection name must be between 1 and 100 characters";
    } else if (!connectionName.match("^[A-Za-z0-9-_S]+$")) {
      invalid = true;
      invalidMessage =
        "Connection name must only consist of ASCII letters, numbers, underscores, and hyphens";
    } else if (
      connectionContext.unavailableNames.includes(
        connectionName.toLocaleLowerCase(),
      )
    ) {
      invalid = true;
      invalidMessage =
        "This name is already used in a Connection or Workspace.";
    } else {
      invalid = false;
      invalidMessage = "";
    }
    setConnectionNameInvalid(invalid);
    setConnectionNameInvalidMessage(invalidMessage);
    connectionContext?.setUnsavedChanges!(true);
    if (props.inputUpdated) props.inputUpdated(event, connectionContext);
  };

  useEffect(() => {
    if (
      !showOAuthClientPrerequisite &&
      !connectionContext.driverInfo!.prerequisite
    ) {
      setPrerequisiteChecked(false);
    }
  }, [connectionContext.driverInfo?.basicProps]); // eslint-disable-line

  // Filter out displayed properties that have the "visible" tag set to false. This is used for the custom SSH properties card.
  // We do this here, instead of inside the hierarchy constructor, because we still want these properties to be counted as required.
  // They render outside this main card, but they still need to be part of the hierarchy.
  const visibleDisplayedProperties =
    props.displayedBasicDriverProperties.filter(
      (property) => property.props?.property?.visible !== false,
    );

  const basicSettingsBody = (
    <Card data-testid="authentication-card">
      <CardBody>
        <Form.Group>
          <h5 className="card-title mb-3">Authentication</h5>
          <SpecialConnectionElements />
          {visibleDisplayedProperties}
        </Form.Group>
      </CardBody>
    </Card>
  );

  let shouldShowCredentialCard =
    !connectionContext.isInitialSetupPage &&
    currentUser.role === UserRole.Admin &&
    (compareStrings(connectionContext.driverType, "Snowflake") ||
      compareStrings(connectionContext.driverType, "Salesforce") ||
      compareStrings(connectionContext.driverType, "Workday") ||
      compareStrings(connectionContext.driverType, "Paylocity") ||
      compareStrings(connectionContext.driverType, "SageIntacct") ||
      compareStrings(connectionContext.driverType, "RaiserEdgeNXT") ||
      compareStrings(connectionContext.driverType, "SharePoint") ||
      compareStrings(connectionContext.driverType, "Monday") ||
      compareStrings(connectionContext.driverType, "RedShift"));

  // Hide the credentials card if the user selects OAuthISU.
  // OAuthISU doesn't allow credentials for individual users.
  if (isOAuthISUSelected(connectionContext.driverInfo)) {
    shouldShowCredentialCard = false;
  }

  useEffect(() => {
    // If the user selects an auth scheme that does not allow the credentials card, e.x. OAuthISU,
    // then we need to reset to Shared Credentials
    if (!shouldShowCredentialCard && currentUser.role === UserRole.Admin) {
      if (
        connectionContext?.connectionInfo?.selectedConnectionType !==
        ConnectionType.Shared
      ) {
        // This function oddly takes in a string instead of the enum value.
        props.onCredentialTypeChange("Shared");
      }
    }

    // If the account switches to a billing plan that does not have UDC, we need to force their existing UDC connections
    // to use shared credentials.
    if (
      connectionContext?.overrideConnectionType &&
      currentUser.role === UserRole.Admin &&
      connectionContext?.connectionInfo?.selectedConnectionType !==
        ConnectionType.Shared
    ) {
      props.onCredentialTypeChange("Shared");
    }
  }, [shouldShowCredentialCard]); // eslint-disable-line

  const credentialCard = shouldShowCredentialCard ? (
    <CredentialCard
      existingConnectionType={
        connectionContext.connectionInfo!.existingConnectionType!
      }
      selectedConnectionType={
        connectionContext.connectionInfo!.selectedConnectionType!
      }
      onCredentialTypeChange={props.onCredentialTypeChange}
      hideCredentialNote={connectionContext.overrideConnectionType}
    />
  ) : null;

  useEffect(() => {
    const form = connectionContext.formRef?.current;

    if (form) {
      const handleInvalid = (event: Event) => {
        event.preventDefault();
        ToastrError(
          "Missing prerequisites",
          "Please ensure all prerequisite steps in the documentation have been completed before attempting to authenticate this connection.",
        );
      };
      form.addEventListener("invalid", handleInvalid, true);

      return () => {
        form.removeEventListener("invalid", handleInvalid, true);
      };
    }
  }, []); // eslint-disable-line

  const prerequisiteWarningCard = (
    <PrerequisiteWarningCard
      isShowing={
        !showOAuthClientPrerequisite &&
        !connectionContext.isOEMConnection! &&
        !connectionContext.isInitialSetupPage! &&
        connectionContext.driverInfo!.prerequisite
      }
      driverName={connectionContext.driverInfo?.name ?? ""}
      docSection="prerequisite"
      message="Prerequisite setup is required for this Connection. Follow the steps in the Prerequisites section of our documentation before providing your authentication details to successfully authenticate."
      prerequisiteChecked={prerequisiteChecked}
      isPrerequisiteCheckInvalid={isPrerequisiteCheckInvalid}
      setPrerequisiteChecked={setPrerequisiteChecked}
      setPrerequisiteCheckInvalid={setPrerequisiteCheckInvalid}
    />
  );

  const prerequisiteWarningCardOAuthClientAuthscheme = (
    <PrerequisiteWarningCard
      isShowing={
        showOAuthClientPrerequisite &&
        !connectionContext.isOEMConnection! &&
        !connectionContext.isInitialSetupPage!
      }
      driverName={connectionContext.driverInfo?.name ?? ""}
      docSection="authentication-methods"
      message="OAuthClient requires creating a custom OAuth app. Follow the steps below before providing your authentication details to successfully authenticate."
      prerequisiteChecked={prerequisiteChecked}
      isPrerequisiteCheckInvalid={isPrerequisiteCheckInvalid}
      setPrerequisiteChecked={setPrerequisiteChecked}
      setPrerequisiteCheckInvalid={setPrerequisiteCheckInvalid}
    />
  );

  return (
    <Container
      data-testid="basic-settings"
      fluid
      className="p-0 pages-connections-components-BasicSettings"
    >
      {connectionContext.isCacheConnection ? null : (
        <>
          <Card
            data-testid="connection-name-card"
            className="connection-name-card"
          >
            <CardBody>
              <Row className="mb-3">
                <Col>
                  <CDataTypography variant="typography-variant-card-title">
                    General
                  </CDataTypography>
                </Col>
              </Row>
              <Row className="details-row">
                <Col className="mb-3">
                  <CDataTypography
                    className="mb-2"
                    variant="typography-variant-body-medium"
                  >
                    Data Source
                  </CDataTypography>
                  <div className="d-flex align-items-center">
                    {getDriverIcon(
                      connectionContext.driverType,
                      "connection-icon me-2 p-0",
                    )}
                    {connectionContext.driverInfo.niceName}
                    {getBetaIcon(
                      connectionContext.driverInfo.beta,
                      "badge-client-page-beta",
                    )}
                  </div>
                </Col>
                <Col className="mb-3">
                  <CDataTypography
                    className="mb-2"
                    variant="typography-variant-body-medium"
                  >
                    Status
                  </CDataTypography>
                  <ConnectionStatusBadge
                    driver={connectionContext.driverType}
                    isTested={
                      connectionContext.connectionInfo.isTested ?? false
                    }
                    isQueryUser={false}
                    isUdcConnectionType={false}
                  />
                </Col>
                <Col className="mb-3">
                  <CDataTypography
                    className="mb-2"
                    variant="typography-variant-body-medium"
                  >
                    Last Modified
                  </CDataTypography>
                  {connectionContext.connectionInfo.connectionLastModified ? (
                    formatUTCDateTime(
                      connectionContext.connectionInfo.connectionLastModified,
                    ) + " UTC"
                  ) : (
                    <CDataTypography className="ps-0">---</CDataTypography>
                  )}
                </Col>
              </Row>
              <Form.Group>
                <CDataTypography
                  className="mb-3"
                  variant="typography-variant-card-title"
                >
                  Connection Name
                </CDataTypography>
                <Form.Control
                  required
                  data-testid="input-connection-name"
                  id="connectionName"
                  name="connectionName"
                  type="text"
                  defaultValue={
                    connectionContext.connectionInfo.existingConnectionName
                  }
                  onChange={validateConnectionName}
                  isInvalid={connectionNameInvalid}
                />
                <Form.Control.Feedback
                  data-testid="input-validation-text"
                  type="invalid"
                >
                  {connectionNameInvalidMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </CardBody>
          </Card>
        </>
      )}
      {prerequisiteWarningCard}
      {prerequisiteWarningCardOAuthClientAuthscheme}
      {hasSSH(connectionContext.driverInfo) && <SSHConnectionTypeSelector />}
      {basicSettingsBody}
      {credentialCard}
    </Container>
  );
}

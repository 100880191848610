import { useContext, useEffect, useState } from "react";
import { SizeMe } from "react-sizeme";
import TitleWithBackButton from "../../../components/TitleWithBackButton";
import { ConnectionCrudCalls } from "../../../hooks/useConnectionCrudCalls";
import { ConnectionContext } from "./ConnectionContext";
import { Col, Row } from "reactstrap";
import TitleRowButtons from "./TitleRowButtons";
import classnames from "classnames";

interface ITitleRow {
  connectionCrudCalls: ConnectionCrudCalls;
  displayedBasicDriverProperties: JSX.Element[];
  finishCustomReports: () => void;
  handleNavigation: (onNavigate: () => void) => void;
  pageName: string;
}

const TitleRow = (props: ITitleRow) => {
  const [rowWidth, setRowWidth] = useState<number | null>(null);
  const [titleWidth, setTitleWidth] = useState<number | null>(null);
  const [isSticky, setIsSticky] = useState(false);
  const {
    driverInfo,
    isCacheConnection,
    isInitialSetupPage,
    isOEMConnection,
    fromSpreadsheetsClient,
  } = useContext(ConnectionContext);
  useEffect(() => {
    // Prevents sticky bar from appearing on initial setup, cache connection, and oem connection pages
    if (!isInitialSetupPage && !isCacheConnection && !isOEMConnection) {
      // If a user scrolls beyond the set threshold, toggle the bar to the sticky version
      const handleScroll = () => {
        if (window.scrollY > 23) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []); // eslint-disable-line

  // If the title width takes up too much of the row, display dropdown ellipses in place of extra buttons
  let shouldAbbreviateButtons = false;
  if (rowWidth && titleWidth && rowWidth - titleWidth < 430) {
    shouldAbbreviateButtons = true;
  }

  return (
    <div
      className={isSticky ? "sticky-title-row" : ""}
      data-testid={isSticky ? "sticky-title-row" : "normal-title-row"}
    >
      <SizeMe>
        {({ size }) => {
          if (rowWidth !== size.width) {
            setRowWidth(size.width);
          }

          return (
            <Row
              className={classnames("title-row align-items-center", {
                "mb-3": !isSticky,
              })}
            >
              <Col className=" title-column hide-overflow-text">
                <SizeMe>
                  {({ size }) => {
                    if (titleWidth !== size.width) {
                      setTitleWidth(size.width);
                    }
                    return (
                      <TitleWithBackButton
                        title={`${props.pageName} ${driverInfo?.niceName} Connection`}
                        isBackButtonHidden={
                          isInitialSetupPage ||
                          isOEMConnection ||
                          fromSpreadsheetsClient
                        }
                        noMargin={true}
                      />
                    );
                  }}
                </SizeMe>
              </Col>
              <TitleRowButtons
                shouldAbbreviateButtons={shouldAbbreviateButtons}
                connectionCrudCalls={props.connectionCrudCalls}
                displayedBasicDriverProperties={
                  props.displayedBasicDriverProperties
                }
                finishCustomReports={props.finishCustomReports}
                handleNavigation={props.handleNavigation}
              />
            </Row>
          );
        }}
      </SizeMe>
    </div>
  );
};

export default TitleRow;
